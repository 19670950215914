import React, { Component } from 'react';
import { Layout } from 'layouts';
import {
    PageHeader,
    TechnologyPartners,
    BrandsWorkedWithUs
} from '@components';
import {
    BetterThanOther,
    MultiColumnList
} from '@components/industries';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/industries';

class Event extends Component {
    render() {
        const socialMedia = pageContent.subPage.socialMedia;
        return (
            <Layout>
                <PageHeader image={socialMedia.headerImage} />

                <Heading
                    title={socialMedia.title}
                    overview={socialMedia.overview}
                    noHeadingIcon={true} />

                <BetterThanOther content={socialMedia.betterThanOther} />

                <MultiColumnList content={socialMedia.services} listStyle={'dot'} listItemTextBold={true} hasBackground={true} />

                <MultiColumnList content={socialMedia.solutions} listTitleHighlighted={true} />

                <MultiColumnList content={socialMedia.features} listStyle={'check'} titleHighlighted={true} listItemTextBold={true} />

                <BetterThanOther content={socialMedia.types} hasBackground={true} />

                <PageNavigation
                    prev={pageContent.navigation.prev}
                    next={pageContent.navigation.next} />

                <TechnologyPartners />

                <BrandsWorkedWithUs />

            </Layout>
        );
    }
}

export default Event;
